import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import InViewMonitor from 'react-inview-monitor'

import CreateHeroHompage from '../components/HeroHomepage'
import VerticalProjects from '../components/VerticalProjectsComponent'
import HomepageWhoWeAre from '../components/HomepageWhoWeAreComponent'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import Favicon from '../favicon-32x32.png'
import ogMetaImg from '../img/an_og_index.jpg'

// styles

// data

// markup
const HomepagePage = ({ data }) => {
  // const {wpPage}
  const { allWpProjectPostType: projects } = data
  const { allWpPage: thepage } = data

  const featuredProjectsElements = data.allWpProjectPostType.edges
  const leIndex = 0
  return (
    <div>
      <Navbar />
      <div key="hp" className="indexHolder">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${data.allWpPage.edges[0].thepage.title} | Interior Design Studio`}</title>
          <meta
            name="description"
            content="The studio, founded in Belgrade in 2013, has the aim of leaving an indelible mark in the hearts of art community and society at large."
          />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
          <meta name="robots" content="index" />
          <link rel="canonical" href="https://ananenadovic.com" />
          <meta
            property="og:title"
            content="Ana Nenadovic | Interior Design Studio"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ananenadovic.com" />
          <meta property="og:image" content={ogMetaImg} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="Ana Nenadovic | Interior Design Studio"
          />
          <meta
            name="twitter:description"
            content="The studio, founded in Belgrade in 2013, has the aim of leaving an indelible mark in the hearts of art community and society at large."
          />
          <meta name="twitter:image" content={ogMetaImg} />
        </Helmet>

        <CreateHeroHompage
          homepageIntroText={data.allWpPage.edges[0].thepage.homepageIntroText}
        />
        <VerticalProjects featuredProjects={featuredProjectsElements} />
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeIn animDelay3 animWidth"
        >
          <hr className="hrSeparator" />
        </InViewMonitor>
        <HomepageWhoWeAre
          whowearehome={data.allWpPage.edges[0].thepage.homepageWhoWeAreSection}
        />

        <div className="stickTheFooter">
          <Footer />
        </div>
      </div>
    </div>
  )
}
HomepagePage.propTypes = {
  data: PropTypes.object.isRequired,
}
export default HomepagePage

export const pageQuery = graphql`
  query ProjectsByHomepage {
    allWpProjectPostType(filter: { locale: { locale: { eq: "en_US" } } }) {
      edges {
        project: node {
          id
          title
          slug
          content
          acfBeforeAfterImages {
            imageafterelement {
              altText
              id
              slug
              title
              caption
              mediaItemUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaDetails {
                height
                width
                file
              }
            }
            imageafterelement {
              altText
              id
              slug
              title
              caption
              mediaItemUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaDetails {
                height
                width
                file
              }
            }
          }
          featuredImage {
            node {
              id
              slug
              link
              sourceUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
            }
          }
          locale {
            locale
          }
          translations {
            locale
            id
            post_title
            href
          }
        }
      }
    }
    allWpPage(
      filter: {
        template: { templateName: { eq: "Homepage" } }
        locale: { locale: { eq: "en_US" } }
      }
    ) {
      edges {
        thepage: node {
          id
          slug
          status
		  title
          template {
            templateName
          }
          locale {
            locale
          }
          translations {
            locale
            id
            post_title
            href
          }
          homepageIntroText {
            homepageintro
          }
          homepageWhoWeAreSection {
            whowearesectionone
            whowearesectiontwo
          }
          homepageProjects {
            selectProjectsToShow {
              ... on WpProjectPostType {
                id
              }
            }
          }
        }
      }
    }
  }
`
